export const RESTOCKING_FEE = 'restocking_fee';
export const COMPLETE = 'complete';
export const CUSTOM = 'custom';
export const CHARGEBACK = 'chargeback';
export const TYPES = [RESTOCKING_FEE, COMPLETE, CUSTOM, CHARGEBACK];

export const RESTOCKING_FEE_STEPS = ['overview', 'confirm', 'confirm_reason', 'success'];
export const COMPLETE_STEPS = ['confirm', 'confirm_reason', 'success'];
export const CUSTOM_STEPS = ['overview', 'confirm', 'confirm_reason', 'success'];
export const CHARGEBACK_STEPS = ['confirm', 'confirm_reason', 'success'];

export default {};
